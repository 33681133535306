import React from "react"
import logo from "../images/seed-cycle-logo-v1.png"
import { Link } from "gatsby"

export default function Layout() {
    return (
        <main>
            <img src={logo} alt="Logo" />
            <div className="menu">
                <Link to="/">Home</Link>
                <Link to="/leaderboard">Leaderboard</Link>
                {/* <Link to="/resources">Resources</Link> */}
            </div>
        </main>
    )
}