import * as React from "react"
import "../styles/main.scss"
import Layout from "../components/layout.js"

// markup
const IndexPage = () => {
  return (
    <main>
      <Layout>Resources</Layout>
      <h2>SeedCycle Macro</h2>
      <p>
        A macro for creating set seed worlds. Make sure to update your SavesFolder and OldWorldsFolder at the top. <br />
        <a href="https://github.com/PodX12/SeedCycleMacro">https://github.com/PodX12/SeedCycleMacro</a>
      </p>

      <h2>Nether Coordinate Converter </h2>
      <p>
        This takes your f3+C and gives you nether coordinates, just remember that every time you build a portal in a world it blocks postion for the next one to generate. <br />
        <a href="http://mcspeedtools.com/">http://mcspeedtools.com/</a>
      </p>


    </main>
  )
}

export default IndexPage
